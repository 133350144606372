import processBrowser from 'browserCheck';

window.__VERSION = process.env.REACT_APP_VERSION;

if (processBrowser()) {
  (async () => {
    const main = await import('main');
    main.start();
  })();
}
