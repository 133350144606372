import * as bowser from 'bowser';

const agent = window.navigator.userAgent;
const isValidChrome = bowser.check({
  chrome: '60',
}, true, agent);
const isValidEdge = bowser.check({
  msedge: '15',
}, true, agent);
const isValidFirefox = bowser.check({
  firefox: '55',
}, true, agent);
const isValidSafari = bowser.check({
  safari: '10',
}, true, agent);
const isValidOpera = bowser.check({
  opera: '48',
}, true, agent);
const isValidAndroid = bowser.check({
  android: '4.4',
}, true, agent);
const isValidiOS = bowser.check({
  ios: '8',
}, true, agent);
const isGooglebot = (bowser as any).googlebot;
const isBingbot = /bingbot/i.test(agent);
const isSupportedBrowser = isValidChrome || isValidFirefox || isValidSafari || isValidOpera
  || isValidEdge || isValidAndroid || isValidiOS || isGooglebot || isBingbot;
export default function processBrowser(): boolean {
  if (!isSupportedBrowser) {
    displayUnsupportedMessage();
  }

  return isSupportedBrowser.valueOf();
}

function displayUnsupportedMessage() {
  const div = document.getElementById('unsupported-notification-container');

  if (div) {
    div.style.visibility = 'visible';
    div.style.display = 'block';

    if (document.body) {
      document.body.appendChild(div);
    }
  }
}
